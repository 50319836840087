@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Fjalla+One&display=swap');


html,
body {
  overflow-x: hidden;
  scroll-behavior: smooth;
  background: url("https://cdn.dribbble.com/users/2156796/screenshots/19992280/media/7d2ee3a4d15d1f335419059066067694.gif");
}

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scrollbar-width: auto;
  scrollbar-color: #afb2bf;
}

 li a{
  width: max-content;
  position: relative;
  cursor: pointer;
} 
 li a::after{
  content: "";
  position: absolute;
  bottom: -5px;
  height: 1px;
  width: 100%;
  left: 0;
  background-color: #272727;
  transition: 0.3s;
  transition-timing-function: easy-in-out;
  transform: scaleX(0)
} 
 li a:hover::after
{
  transform: scaleX(1)
}

.background{
  /* height: calc(100vh - 60px); */
  /* background-color: #264AC5; */
  /* background: linear-gradient(diagonally , #ffffff 20%, #8dbef3 40%, #68a7eb 60%, #3d92ec 80%, #0174ee 100%); */

  background: linear-gradient(10.21deg, #264ac5 40.91%, #eaeeff 111.58%);

  /* background-image: url("./assests/hero-section-2.jpg"); */
 /* background-size: cover; */
}




.bgimage{
  height: 300px;
  width:330px;
  background-size: cover;
  background-image: url("./assests/about.jpg");
  background-attachment: scroll;
}

.cont{
  background: rgb(255,255,255);
  background: linear-gradient(to bottom, rgba(255,255,255,1) 0%, rgba(97, 144, 197, 0.774) 50% ,  rgb(78, 111, 161) 100%);

 
}


.icon {
  font-size: 42px;
  width: 120px;
  height: 120px;
  padding: 40px 0;
  /* background: linear-gradient(to right, #6372ff 0%, #5ca9fb 100%);*/
  background: rgb(63,228,251);
background: radial-gradient(circle, rgba(63,228,251,0.6867121848739496) 0%, rgba(70,116,252,1) 100%);
  border-radius: 50%;
  color: #fff;
  box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.05);
}

.btn-pink {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: green;
  /* width: fit-content; */
  color: white;
  padding: 0.5rem;
  /* box-shadow: 5px 5px 7px 0px #ef476f3f; */
  font-size: 3rem;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.5s;
  font-weight: 500;
  border: solid 3px transparent;
  position: relative;
  z-index: 1;
  /* border-radius: 5px; */
}

.btn-pink::before {
  content: "";
  position: absolute;
  top: 0px;
  left: 0;
  right: 0;
  bottom: 0px;
  z-index: -1;
  transform: scaleX(0);
  transform-origin: left;
  transition: all 0.8s;
}

.btn-pink:hover::before {
  transform: scaleX(1);
}

.btn-pink:hover {
  border: solid 3px #115a15;
  /* color: green; */
  scale: 1.1;
} 











.container {
  background-color: black;
  color: #fff;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.loading-page {
  position: fixed;
  top: 0;
  left: 0;
  /* background: linear-gradient(to right, #2c5364, #203a43, #0f2027); */

  background: rgb(58,83,180);
  background: linear-gradient(90deg, rgba(58,83,180,0.9864320728291317) 10%, rgb(56, 87, 52) 51%, rgba(50,86,200,1) 100%, rgba(252,69,69,1) 100%);

  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  align-items: center;
  justify-content: center;
  color: #191654;
  z-index: 10000;
}

#svg {
  height: 150px;
  /* width: 250px;  */
  width: 250px; 

  stroke: rgb(240, 233, 233);
  fill-opacity: 0;
  stroke-width: 3px;
  stroke-dasharray: 4500;
  animation: draw 3s ease;
}

@keyframes draw {
  0% {
    stroke-dashoffset: 4500;
  }
  100% {
    stroke-dashoffset: 0;
  }
}

/* .name-container {
  height: 30px;
  overflow: hidden;
  
} */

.logo-name {
  color: #e49494;
  font-size: 20px;
  letter-spacing: 2px;
  text-transform: uppercase;
  margin-left: 20px;
  font-weight: bolder;
}
.logo-name-slogan {
  color: #ddf506;
  font-size: 13px;
  letter-spacing: 3px;
  text-transform: uppercase;
  margin-left: 10px;
  /* font-weight: bolder; */
}



.active-link {
  /* color: #4a90e2; */
  /* position: relative; Required for positioning the pseudo-element */
  /* background: #314b81; */
  color: #0043fa;
  font-weight: 900;
  padding: 4px 8px 4px 8px;
  border-radius: 20px;
}

.projects{
  background: rgb(255,255,255);
background: linear-gradient(to bottom, rgba(255,255,255,1) 0%, rgba(155, 195, 250, 0.774) 100%);
}

.team{
  min-height: 100vh;
  background: rgb(255,255,255);
  background: linear-gradient(to top, rgba(255,255,255,1) 0%, rgba(155, 195, 250, 0.774) 100%);
}



.workCard:nth-child(even):hover {
  background-color: #f78da7;
}

.workCard:nth-child(odd):hover {
  background-color: #fcb900;
}


@media only screen and (max-width: 600px) {
 .mobile{
  display: none;
 }

 .head-media{
  gap:4em;
 }
}
























@keyframes rotateCube {
  from {
    transform: rotateY(0deg);
  }
  to {
    transform: rotateY(360deg);
  }
}

.cube-container {
  width: 100px;
  height: 100px;
  perspective: 500px;
  margin: auto;
  position: relative;
}

.cube {
  width: 100%;
  height: 100%;
  position: absolute;
  transform-style: preserve-3d;
  animation: rotateCube 7s linear infinite;
}

.cube-face {
  position: absolute;
  width: 100px;
  height: 100px;
  background: white;
  border: 1px solid #ccc;
}

.cube-face img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Positioning the cube faces */
.cube-face.front  { transform: rotateY(  0deg) translateZ(50px); }
.cube-face.back   { transform: rotateY(180deg) translateZ(50px); }
.cube-face.right  { transform: rotateY( 90deg) translateZ(50px); }
.cube-face.left   { transform: rotateY(-90deg) translateZ(50px); }
.cube-face.top    { transform: rotateX( 90deg) translateZ(50px); }
.cube-face.bottom { transform: rotateX(-90deg) translateZ(50px); }


.bghero {
  height: 90vh;
  background-image: url("https://images.unsplash.com/photo-1620365602462-40d8f2cdd84c?q=80&w=1471&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D");
  background-size: cover; /* Ensures the image covers the entire area */
  background-position: center; /* Centers the image */
  background-repeat: no-repeat; /* Prevents the image from repeating */
}